<template>
  <b-modal
    id="modal-result-train-check-adult-ticket"
    :title="$t('train.search.result.unaccompaniedChild')"
    title-class="font-medium-4 fw-600 text-primary"
    header-bg-variant="light-primary"
    centered
    @hide="hideModal"
    @show="showModal"
  >
    <IAmOverlay
      :loading="loading"
      :spinner-variant="'primary'"
    >
      <b-alert
        show
        variant="info"
        class="px-1 py-50"
      >
        {{ $t('train.search.result.unaccompaniedChildNotice') }}
      </b-alert>

      <b-card>
        <validation-provider
          #default="{ errors }"
          name="Số vé người lớn"
          :rules="`required`"
        >
          <b-form-group
            v-if="!getIsSearchRT"
            label-for="adultTicketNumber"
          >
            <template #label>
              <div class="text-heading-5">
                {{ $t('train.search.result.adultTicketNumber') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>

            <b-form-input
              id="adultTicketNumber"
              v-model="adultTicketNumber"
              v-remove-non-numeric-chars.allNumber
              :name="$t('myAccount.phone')"
              :placeholder="$t('train.search.result.adultTicketNumber')"
              :class="`${getIsSearchRT ? 'cursor-not-allowed' : '' }`"
              :state="errors[0] ? false : null"
              :disabled="getIsSearchRT"
              :formatter="trimAllInput"
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-alert
          v-if="getIsSearchRT"
          variant="danger"
          show
          class="px-1 py-50 my-50 fw-700"
        >
          {{ $t('train.search.result.childRoundTripNotice') }}
        </b-alert>
      </b-card>
    </IAmOverlay>

    <template #modal-footer="{ cancel }">
      <div class="d-flex justify-content-end w-100">
        <b-button
          size="md"
          variant="outline-secondary"
          class="center mr-2"
          @click="cancel()"
        >
          {{ $t('reservation.back') }}
        </b-button>
        <b-button
          v-if="!getIsSearchRT"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-success border-right-0"
          :disabled="isEmpty(adultTicketNumber)"
          @click="handleSubmit"
        >
          <div class="d-flex-center px-25">
            {{ $t('train.search.result.confirm') }}
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BButton, BFormGroup, BModal, BAlert, BCard, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'
import IAmOverlay from '@/components/IAmOverlay.vue'
import { trimAllInput } from '@/@core/comp-functions/forms/formatter-input'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BModal,
    BButton,
    BAlert,
    BCard,
    BFormGroup,
    BFormInput,
    IAmOverlay,
    BFormInvalidFeedback,

    ValidationProvider,
  },
  setup(props, { emit }) {
    const {
      delay,
      stateTicketSelectedArray,
      getIsSearchRT,
    } = useTrainHandle()

    const adultTicketNumber = ref('')

    const invalid = ref()
    const loading = ref(false)

    async function handleSubmit() {
      const req = {
        children: [],
        returnChildren: [],
        adultTicketId: Number(adultTicketNumber.value),
      }

      const getItem = tk => ({
        ticketId: tk.ticketId,
        physicalTrainId: tk.physicalTrainId,
        coachId: tk.coachId,
        seatId: tk.seatId,
        trainId: tk.trainId,
      })

      stateTicketSelectedArray.value.forEach((store, index) => {
        if (index) {
          store.forEach(tk => {
            const item = getItem(tk)
            req.returnChildren.push(item)
          })
        } else {
          store.forEach(tk => {
            const item = getItem(tk)
            req.children.push(item)
          })
        }
      })

      loading.value = true

      await store.dispatch('app-train/checkAdultTicket', req)
        .then(res => {
          console.log({ checkAdultTicket: res })
          emit('confirm', { adultTicketId: Number(adultTicketNumber.value) })
          this.$bvModal.hide('modal-result-train-check-adult-ticket')
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          loading.value = false
        })
    }

    function showModal() { }

    function hideModal() {
      invalid.value = undefined
      adultTicketNumber.value = ''
    }

    return {
      adultTicketNumber,
      invalid,
      showModal,
      hideModal,
      handleSubmit,
      loading,
      trimAllInput,
      isEmpty,
      getIsSearchRT,
    }
  },
}
</script>
