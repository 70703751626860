var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-result-train-check-adult-ticket",
      "title": _vm.$t('train.search.result.unaccompaniedChild'),
      "title-class": "font-medium-4 fw-600 text-primary",
      "header-bg-variant": "light-primary",
      "centered": ""
    },
    on: {
      "hide": _vm.hideModal,
      "show": _vm.showModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('div', {
          staticClass: "d-flex justify-content-end w-100"
        }, [_c('b-button', {
          staticClass: "center mr-2",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), !_vm.getIsSearchRT ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-success border-right-0",
          attrs: {
            "disabled": _vm.isEmpty(_vm.adultTicketNumber)
          },
          on: {
            "click": _vm.handleSubmit
          }
        }, [_c('div', {
          staticClass: "d-flex-center px-25"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.confirm')) + " ")])]) : _vm._e()], 1)];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading,
      "spinner-variant": 'primary'
    }
  }, [_c('b-alert', {
    staticClass: "px-1 py-50",
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.unaccompaniedChildNotice')) + " ")]), _c('b-card', [_c('validation-provider', {
    attrs: {
      "name": "Số vé người lớn",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [!_vm.getIsSearchRT ? _c('b-form-group', {
          attrs: {
            "label-for": "adultTicketNumber"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-heading-5"
              }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.adultTicketNumber')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          class: "".concat(_vm.getIsSearchRT ? 'cursor-not-allowed' : ''),
          attrs: {
            "id": "adultTicketNumber",
            "name": _vm.$t('myAccount.phone'),
            "placeholder": _vm.$t('train.search.result.adultTicketNumber'),
            "state": errors[0] ? false : null,
            "disabled": _vm.getIsSearchRT,
            "formatter": _vm.trimAllInput
          },
          model: {
            value: _vm.adultTicketNumber,
            callback: function callback($$v) {
              _vm.adultTicketNumber = $$v;
            },
            expression: "adultTicketNumber"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1) : _vm._e()];
      }
    }])
  }), _vm.getIsSearchRT ? _c('b-alert', {
    staticClass: "px-1 py-50 my-50 fw-700",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.childRoundTripNotice')) + " ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }